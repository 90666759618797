import React from 'react';
import './styles/style.less';
import ReactDOM from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';
import isValidProp from '@emotion/is-prop-valid';
import { App } from './containers';

// @ts-ignore
module.hot?.accept();

const root = ReactDOM.createRoot(document.getElementById('container') as HTMLElement);

function initApp() {
	root.render(
			<StyleSheetManager
				shouldForwardProp={(propName) =>
					isValidProp(propName)
				}
			>,
				<App />,
			</StyleSheetManager>
	);
}

window.addEventListener('load', initApp);
